<script>
    import { createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();

    export let name;
    export let placeholder;
    export let options;
    export let value;
    export let required;

    function updateValue() {
        for (let ri = 0; ri < options.length; ri++) {
            if(document.getElementById(name+'radio'+ri).checked) {
                value = document.getElementById(name+'radio'+ri).value;
                break;
            }
        }
        dispatch('update', {
            id: name,
			value: value,
            valid: true,
            error: ''
		});
    }
</script>

<div class="input__container mb-4">
    <label for="{name}" class="form-label">
        {placeholder} 
        {#if required}
            <span class="error">*</span>
        {/if}
    </label>  
    <div class="form-radio d-flex w-100 justify-content-start align-items-center">
        {#each options as o, i}
            <div class="form-check">
                <input type="radio" class="form-check-input" id="{name}radio{i}" name="{name}" value="{o}" on:click={updateValue} checked={value == o}/>{o}<label class="form-check-label" for="{name}radio{i}"></label>
            </div>
        {/each}
    </div>
</div>