<script>
    import { createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();

    export let name;
    export let required;
    export let placeholder;
    var tmpValue = '';
    export let err = '';
    export let valid = false;
    var bannedMails = ['@yopmail', '@lidte', '@vmani', '@seacob', '@firemailbox', '@tzymail', '@jourrapide', '@armyspy', '@cuvox', '@dayrep', '@einrot', '@fleckens', '@gustr', '@rhyta', '@superrito', '@teleworm'];
    let e = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gm;

    function updateValue(val, valid, err) {
        dispatch('update', {
            id: name,
			value: val,
            valid: valid,
            error: err
		});
    }

    function verifyValue() {
        tmpValue = tmpValue.trim();
        let test = tmpValue.match(e);
        let isBanned = false;

        if(required) {
            if(tmpValue != '') {
                if(test) {
                    bannedMails.forEach(b => {
                        if (tmpValue.includes(b)) isBanned = true;
                    });

                    if(isBanned == false) {
                        valid = true;
                        err = '';
                        updateValue(tmpValue, valid, err);
                    } else {
                        valid = false;
                        err = 'Adresse mail bannie';
                        updateValue(tmpValue, valid, err);
                    }
                } else {
                    valid = false;
                    err = 'Adresse mail invalide';
                    updateValue(tmpValue, valid, err);
                }
            } else {
                valid = false;
                err = 'Adresse mail vide';
                updateValue(tmpValue, valid, err);
            }
        } else {
            if(tmpValue != '') {
                if(test) {
                    bannedMails.forEach(b => {
                        if (tmpValue.includes(b)) isBanned = true;
                    });

                    if(isBanned == false) {
                        valid = true;
                        err = '';
                        updateValue(tmpValue, valid, err);
                    } else {
                        valid = false;
                        err = 'Adresse mail bannie';
                        updateValue(tmpValue, valid, err);
                    }
                } else {
                    valid = false;
                    err = 'Adresse mail invalide';
                    updateValue(tmpValue, valid, err);
                }
            }
        }
    }
</script>

<div class="input__container mb-4">
    <label for="{name}" class="form-label">
        {placeholder} 
        {#if required}
            <span class="error">*</span>
        {/if}
    </label>
    <input type="text" class="form-control" name="{name}" id="{name}" required={required} placeholder={placeholder} bind:value={tmpValue}  on:change={verifyValue} class:error={err != ''} class:valid={valid}>
    <span class="input__status" class:valid={valid} class:err={err != ''}>
        {#if valid}
            <i class="fa-solid fa-check"></i>
        {:else if err != ''}
            <span>{err}</span> <i class="fa-solid fa-xmark"></i>
        {/if}
    </span>
</div>