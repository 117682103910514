<script>
	import TextInput from './inputs/textInput.svelte';
	import NameInput from './inputs/nameInput.svelte';
	import EmailInput from './inputs/emailInput.svelte';
	import PhoneInput from './inputs/phoneInput.svelte';
	import AddressInput from './inputs/addressInput.svelte';
	import ParagraphInput from './inputs/paragraphInput.svelte';
	import RadioInput from './inputs/radioInput.svelte';
	import CheckboxInput from './inputs/checkboxInput.svelte';

	// Form object
	var form = {
		name: 'contact',
		state: "init", // <sending, valid, error, init>
		isValid: false,
		isEmpty: true,
		mailSent: false,
		mailErr: "",
		inputsBlank: {
			"company": {
				type: "text",
				value: '',
				valid: false,
				error: '',
				required: false,
				placeholder: 'Société'
			},
			"firstname": {
				type: "name",
				value: '',
				valid: false,
				error: '',
				required: true,
				checked: false,
				options: ['','',''],
				placeholder: 'Prénom'
			},
			"lastname": {
				type: "name",
				value: '',
				valid: false,
				error: '',
				required: true,
				placeholder: 'Nom'
			},
			"phone": {
				type: "phone",
				value: '',
				valid: false,
				error: '',
				required: true,
				placeholder: 'N° de téléphone'
			},
			"email": {
				type: "email",
				value: '',
				valid: false,
				error: '',
				required: true,
				placeholder: 'Adresse E-mail'
			},
			"message": {
				type: "large-text",
				value: '',
				valid: false,
				error: '',
				required: false,
				placeholder: 'Message'
			}
		},
		inputs: {
			"company": {
				type: "text",
				value: '',
				valid: false,
				error: '',
				required: false,
				placeholder: 'Hôpital / Clinique / Cabinet'
			},
			"firstname": {
				type: "name",
				value: '',
				valid: false,
				error: '',
				required: true,
				checked: false,
				options: ['','',''],
				placeholder: 'Prénom'
			},
			"lastname": {
				type: "name",
				value: '',
				valid: false,
				error: '',
				required: true,
				placeholder: 'Nom'
			},
			"phone": {
				type: "phone",
				value: '',
				valid: false,
				error: '',
				required: true,
				placeholder: 'N° de téléphone'
			},
			"email": {
				type: "email",
				value: '',
				valid: false,
				error: '',
				required: true,
				placeholder: 'Adresse E-mail'
			},
			"message": {
				type: "large-text",
				value: '',
				valid: false,
				error: '',
				required: true,
				placeholder: 'Message'
			}
		},
		inputRows: [ // Inputs placement
			[{id: 'company', width: 12}],
			[{id: 'firstname', width: 5}, {id: 'lastname', width: 7}],
			[{id: 'email', width: 7}, {id: 'phone', width: 5}],
			[{id: 'message', width: 12}]
		],
	};

	var scripts = {
		sendForm: async () => {
			if(form.isValid && !form.isEmpty) {
				
				let formData = new FormData(document.querySelector('.form__contact'));
				form.state = "sending";

				try {
					var res = await fetch("__mailer.php", {
						method: "POST",
						body: formData
					});
					var resData = await res.json();

					if(resData.status == "ok") {
						form.mailSent = true;
						scripts.resetForm();
						form.state = "init";
					} else {
						form.state = "error";
						form.mailErr = resData.error;
					}
				} catch (e) {
					form.mailErr = e;
					form.state = "error";
				}
			}
		},
		updateBtn: () => {
			form.state = "init";
			form.isValid = false;
			form.isEmpty = true;

			let isOneInputInvalid = false;
			let globalTmpValue = '';

			for (var fi in form.inputs) {
				if (form.inputs.hasOwnProperty(fi)) {
					globalTmpValue = globalTmpValue + ' ' + form.inputs[fi].value;
				}
			}

			if(globalTmpValue != '') {
				for (var fii in form.inputs) {
					if (form.inputs.hasOwnProperty(fii)) {
						if((form.inputs[fii].valid == false && form.inputs[fii].value != '') || (form.inputs[fii].value == '' && form.inputs[fii].required == true)) {
							isOneInputInvalid = true;
						}
					}
				}

				if(isOneInputInvalid) {
					form.state = "error";
					form.isValid = false;
					form.isEmpty = false;
				} else {
					form.state = "valid";
					form.isValid = true;
					form.isEmpty = false;
				}
			} else {
				form.state = "init";
				form.isValid = false;
				form.isEmpty = true;
			}
		},
		resetForm: () => {
			document.querySelector('.form__contact').reset();
			form.inputs = form.inputsBlank;
		},
		updateValue: (event) => {
			try {
				let data = event.detail;

				form.inputs[data.id].value = data.value;
				form.inputs[data.id].error = data.error;
				form.inputs[data.id].valid = data.valid;
			} catch (e) {
				console.log(e);
				form.mailErr = e;
				form.state = "error";
			} finally {
				scripts.updateBtn();
			}
		}
	};
</script>

{#if form.state == "sending"}
	<div class="form__loading">
		<div class="spinner-border spinner-border text-primary"></div><strong class="px-2">Envois de votre e-mail..</strong>
	</div>
{:else if form.mailSent == true}
	<div class="alert alert-success alert-dismissible">
		<button type="button" class="btn-close" data-bs-dismiss="alert"></button>
		Votre message a bien été envoyé !
	</div>
{:else}
	{#if form.mailErr != ''}
		<div class="alert alert-danger alert-dismissible">
			<button type="button" class="btn-close" data-bs-dismiss="alert"></button>
			{form.mailErr}
		</div>
	{/if}
	<form action="" class="form__contact" method="POST" accept-charset="UTF-8">
		<input type="hidden" name="6a5f46a" id="6a5f46a" value="">
		<input type="hidden" name="address" id="address">
		{#each form.inputRows as input}
			<div class="row">
				{#each input as i}
					<div class="col-sm-{i.width}">
						{#if form.inputs[i.id].type == "text"}
							<TextInput name={i.id} placeholder={form.inputs[i.id].placeholder} required={form.inputs[i.id].required} on:update={scripts.updateValue} />
						{:else if form.inputs[i.id].type == "large-text"}
							<ParagraphInput name={i.id} placeholder={form.inputs[i.id].placeholder} minChars={10} required={form.inputs[i.id].required} on:update={scripts.updateValue} />
						{:else if form.inputs[i.id].type == "name"}
							<NameInput name={i.id} placeholder={form.inputs[i.id].placeholder} required={form.inputs[i.id].required} on:update={scripts.updateValue} />
						{:else if form.inputs[i.id].type == "email"}
							<EmailInput name={i.id} placeholder={form.inputs[i.id].placeholder} required={form.inputs[i.id].required} on:update={scripts.updateValue} />
						{:else if form.inputs[i.id].type == "phone"}
							<PhoneInput name={i.id} placeholder={form.inputs[i.id].placeholder} required={form.inputs[i.id].required} on:update={scripts.updateValue} />
						{:else if form.inputs[i.id].type == "address"}
							<AddressInput name={i.id} title={"Adresse de facturation"} required={form.inputs[i.id].required} on:update={scripts.updateValue} />
						{:else if form.inputs[i.id].type == "radio"}
							<RadioInput name={i.id} required={form.inputs[i.id].required} value={form.inputs[i.id].value} placeholder={form.inputs[i.id].placeholder} options={form.inputs[i.id].options} on:update={scripts.updateValue} />
						{:else if form.inputs[i.id].type == "checkbox"}
							<CheckboxInput name={i.id} required={form.inputs[i.id].required} placeholder={form.inputs[i.id].placeholder} checked={form.inputs[i.id].checked} on:update={scripts.updateValue} />
						{/if}
					</div>
				{/each}
			</div>
		{/each}

		<div class="row">
			<div class="col-sm-12 text-end">
				<button type="button" class="btn btn-success" class:disabled={(form.state == "init" && form.isValid == false) || form.state == "error"} class:btn-info={form.state == "sending"} on:click={scripts.sendForm}>
					{#if form.state == "valid"}
						<i class="fa-solid fa-paper-plane"></i> Envoyer
					{:else if form.state == "sending"}
						<div class="spinner-border spinner-border-sm"></div> Envois
					{:else}
						Envoyer
					{/if}
				</button>
			</div>
		</div>
	</form>
{/if}