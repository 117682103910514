<script>
    import { createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();

    export let name;
    export let placeholder;
    export let checked;
    export let required;
    let value = null;

    function updateValue() {
        if(document.getElementById(name).checked == true) {
            value = true;
        } else {
            value = false;
        }
        dispatch('update', {
            id: name,
			value: value,
            valid: true,
            error: ''
		});
    }
</script>

<div class="input__container mb-4">
    <label for="{name}" class="form-label">
        {placeholder} 
        {#if required}
            <span class="error">*</span>
        {/if}
    </label>
    <div class="form-check">
        <input class="form-check-input" type="checkbox" id="{name}" name="{name}" checked={checked} on:click={updateValue}>
        <label class="form-check-label" for="{name}">{placeholder}</label>
    </div> 
</div>