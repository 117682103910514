<script>
    import { createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();

    export let name;
    export let required;
    export let err = '';
    export let valid = false;
    export let title = '';

    var isFocused = false;
    var street = '';
    var number = '';
    var npa = '';
    var city = '';
    let sce = /[^a-zA-Z\s\-àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ€'.,;:!?()0-9]+/igm;
    let nne = /[^0-9]+/igm;

    function updateValue(val, valid, err) {
        dispatch('update', {
            isAddress: true,
            id: name,
			value: {
                s: val.s,
                n: val.n,
                np: val.np,
                c: val.c
            },
            valid: valid,
            error: err
		});
    }

    function verifyValue() {
        let testCity = '';
        let testNpa = '';
        let testNumber = '';
        let testStreet = '';

        if(required) {
            if(street != '') {
                testStreet = street.match(sce);
            }
            if(number != '') {
                testNumber = number.match(nne);
            }
            if(npa != '') {
                testNpa = number.match(nne);
            }
            if(city != '') {
                testCity = city.match(sce);
            }

            if(testCity || testNpa || testNumber || testStreet) {
                valid = false;
                err = `Caractères invalides: ${testCity + testNpa + testNumber + testStreet}`;
                updateValue({s:street,n:number,np:npa,c:city}, valid, err);
            } else if(street == '' || city == '' || number == '' || npa == '') {
                valid = false;
                err = 'Champ(s) vide';
                updateValue({s:'',n:'',np:'',c:''}, valid, err);
            } else {
                valid = true;
                err = '';
                updateValue({s:street,n:number,np:npa,c:city}, valid, err);
            }
        } else {
            if(street != '') {
                testStreet = street.match(sce);
                if(testStreet == null) {
                    testStreet = '';
                }
            }
            if(number != '') {
                testNumber = number.match(nne);
                if(testNumber == null) {
                    testNumber = '';
                }
            }
            if(npa != '') {
                testNpa = npa.match(nne);
                if(testNpa == null) {
                    testNpa = '';
                }
            }
            if(city != '') {
                testCity = city.match(sce);
                if(testCity == null) {
                    testCity = '';
                }
            }

            if(testCity || testNpa || testNumber || testStreet) {
                valid = false;
                err = `Caractères invalides: ${testStreet + testNumber + testNpa + testCity}`;
                updateValue({s:street,n:number,np:npa,c:city}, valid, err);
            } else {
                if((street+npa+number+city).trim() != '') {
                    valid = true;
                    err = '';
                    updateValue({s:street,n:number,np:npa,c:city}, valid, err);
                } else {
                    valid = false;
                    err = '';
                    updateValue({s:'',n:'',np:'',c:''}, valid, err);
                }
            }
        }
    }
</script>

<label for="{name}" class="form-label">
    {title} 
    {#if required}
        <span class="error">*</span>
    {/if}
</label>
<div class="multi__input__container mb-4" name="address" id="address">
    <div class="multi__input">
        <input type="text" name="{name}street" id="{name}street" placeholder="Rue" class="a40" bind:value={street} on:focus={() => {isFocused = true;}} on:blur={() => {isFocused = false;}} on:keyup={verifyValue} on:change={verifyValue}>
        <span class="divider"></span>
        <input type="text" name="{name}num" id="{name}num" placeholder="N°" class="a10" bind:value={number} on:focus={() => {isFocused = true;}} on:blur={() => {isFocused = false;}} on:keyup={verifyValue} on:change={verifyValue}>
        <span class="divider"></span>
        <input type="text" name="{name}npa" id="{name}npa" placeholder="NPA" class="a10" bind:value={npa} on:focus={() => {isFocused = true;}} on:blur={() => {isFocused = false;}} on:keyup={verifyValue} on:change={verifyValue}>
        <span class="divider"></span>
        <input type="text" name="{name}city" id="{name}city" placeholder="Ville" class="a40" bind:value={city} on:focus={() => {isFocused = true;}} on:blur={() => {isFocused = false;}} on:keyup={verifyValue} on:change={verifyValue}>
    </div>
    <div class="multi__input__background" class:focused={isFocused} class:valid={valid} class:error={err}></div>
    <span class="input__status" class:valid={valid} class:err={err != ''}>
        {#if valid}
            <i class="fa-solid fa-check"></i>
        {:else if err != ''}
            <span>{err}</span> <i class="fa-solid fa-xmark"></i>
        {/if}
    </span>
</div>