<script>
    import { createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();

    export let name;
    export let required;
    export let placeholder;
    var tmpValue = '';
    export let err = '';
    let e = /[^a-zA-Z\s\-àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ&']+/igm;
    export let valid = false;

    function updateValue(val, valid, err) {
        dispatch('update', {
            id: name,
			value: val,
            valid: valid,
            error: err
		});
    }

    function verifyValue() {
        if ((tmpValue == '' || tmpValue == null || tmpValue.trim() == '') && required) {
            valid = false;
            tmpValue = null;
            err = 'Champ vide';
            updateValue('', false, err);
        } else if((tmpValue == '' || tmpValue == null || tmpValue.trim() == '')) {
            valid = false;
            err = '';
            tmpValue = null;
            updateValue('', false, err);
        } else {
            let test = tmpValue.match(e);

            if(test) {
                valid = false;
                err = `Caractères invalides: ${test}`;
                updateValue(tmpValue, false, err);
            } else {
                if(tmpValue.length < 1) {
                    valid = false;
                    err = '1 caractere minimum';
                    updateValue(tmpValue, true, err);
                } else {
                    valid = true;
                    err = '';
                    updateValue(tmpValue, true, err);
                }
            }
        }
    }
</script>

<div class="input__container mb-4">
    <label for="{name}" class="form-label">
        {placeholder} 
        {#if required}
            <span class="error">*</span>
        {/if}
    </label>
    <input type="text" class="form-control" name="{name}" id="{name}" required={required} placeholder={placeholder} bind:value={tmpValue} on:keyup={verifyValue} on:change={verifyValue} class:error={err != ''} class:valid={valid}>
    <span class="input__status" class:valid={valid} class:err={err != ''}>
        {#if valid}
            <i class="fa-solid fa-check"></i>
        {:else if err != ''}
            <span>{err}</span> <i class="fa-solid fa-xmark"></i>
        {/if}
    </span>
</div>